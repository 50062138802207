//
//
//
//
//
//
//
//
//
//
//
//
import BeModal from "@/components/BeModal";
import { ROOM_CHANNEL_MESSAGES } from "@/messages/System";
export default {
  name: "ConfirmModal",
  mixins: [],
  components: {
    BeModal: BeModal
  },
  props: {
    open: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ROOM_CHANNEL_MESSAGES.NAUGHTY_WARNING
    },
    title: {
      type: String,
      default: ""
    },
    subTitle: {
      type: String,
      default: ""
    },
    modalOptions: {
      type: Object,
      default: function _default() {
        return {
          id: "text-modal"
        };
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {
    open: function open(val) {
      if (val) this.$bvModal.show(this.modalOptions.id);
    }
  },
  mounted: function mounted() {
    if (this.open) this.$bvModal.show(this.modalOptions.id);
  },
  methods: {
    resetModal: function resetModal() {
      this.$emit("hidden");
    },
    closeConfirmModal: function closeConfirmModal() {
      this.$emit("cancel-confirm-modal");
      this.$bvModal.hide(this.modalOptions.id);
    },
    handleConfirmModal: function handleConfirmModal() {
      this.$emit("ok-confirm-modal");
      this.$bvModal.hide(this.modalOptions.id);
    }
  }
};